<template>
  <div v-myTitle :data-title="textTitle">
    <div class="login" id="login">
<!--      <h1 class="title">TACK</h1>-->
      <h1 class="title" style="margin-top: 100px;">{{ this.$title }}</h1>
      <div class="ident">
        <span class="identext">{{ type == 2 ? this.$manager : this.$staff }}  登录</span>
      </div>
      <input
        class="loginInput"
        type="text"
        v-model="uname"
        placeholder="请输入邮箱或手机号码"
      />

      <div class="btn1">
        <button class="loginbtn" @click="login">登录</button>
        <button class="loginbtn" @click="back">返回</button>
      </div>

    </div>
  </div>
</template>

<script>
import { login } from '@/request/api'; // 导入我们的api接口

export default {
  name: "Login",
  data() {
    return {
      textTitle: this.$title,
      type: 1,
      uname: "",
    };
  },
  created() {
    if (this.$route.params.type) {
      this.type = this.$route.params.type;
    }
  },
  methods: {
    login() {
      if (!this.uname) {
        this.$toast("请输入电话或者邮箱")
      } else {
        console.log(this.type, 'type')
        login({
          uname: this.uname,
          type: this.type
        }).then((res) => {
          console.log(res);
          if (res) {
            this.$store.commit("setToken", "Bearer " + res.data.api_token);
            this.$store.commit("setUserInfo", res.data);
            if (res.data.type == 1) {
              this.$router.replace("/explain");
            } else {
              this.$router.replace("/scores");
            }

          }
        });
      }
    },
    back() {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped>
.login {
  background: url("../assets/img/index1.jpg");
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-size: cover;
  position: absolute;
}
.title {
  color: #0089c4;
  margin: 0;
}
.ident {
  margin-top: 20px;
}
.identext {
  color: black;
  font-size: 14px;
}

.loginInput {
  width: 80%;
  height: 30px;
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 0 0 0 2px rgb(255, 255, 255), 0 0 5px 3px rgb(37, 161, 221);
  margin-top: 20px;
  text-align: center;
  border-radius: 5px;
  outline: none;
  -webkit-appearance: none;
}

input::-webkit-input-placeholder {
  font-size: 14px;
  color: #d0d0d0;
}

.loginbtn {
  width: 45%;
  height: 30px;
  margin: 30px 0;
  font-weight: bold;
  border-radius: 5px;
  display: block;
  margin-top: 15px;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 0 0 0 2px rgb(255, 255, 255), 0 0 5px 3px rgb(37, 161, 221);
  background-image: linear-gradient(#00B3F1, #00A3EF);
}

.btn1{
  text-align: center;
  margin-top: 60px;
  margin-left: 34%;
}

</style>
